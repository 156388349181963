import React, {
  useReducer,
  createContext,
  SFC,
  useContext,
} from "react"

import { reducer, Dispatch } from "../reducers/reducers"
import { defaultState, AppState } from "./defaultState"

export type AppProviderProps = {
  children: React.ReactNode
}

const AppStateContext = createContext<AppState>(defaultState)
const AppDispatchContext = createContext<Dispatch | undefined>(undefined)

const useAppState = () => useContext(AppStateContext)
const useAppDispatch = () => useContext(AppDispatchContext)

const AppProvider = ({ children }: AppProviderProps) => {
  const [AppState, dispatch] = useReducer(reducer, defaultState)

  return (
    <AppStateContext.Provider value={AppState}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  )
}

export { AppProvider, useAppState, useAppDispatch }
