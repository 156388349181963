import {
  AppState,
  documentTypes,
  outputDataTypes,
} from "../context/defaultState"

type Action =
  | { type: "RESET"; payload: AppState }
  | { type: "SELECT_DOCUMENT"; payload: documentTypes }
  | { type: "UPDATE_DOCUMENT_TEXT_INPUT"; payload: string }
  | { type: "DATA_LOADING"; payload: boolean }
  | { type: "SET_OUTPUT"; payload: outputDataTypes }
  | { type: "SET_RESPONSE_ERROR"; payload: boolean }
  | { type: "SET_RESPONSE_ERROR_MSG"; payload: string }
  | { type: "UPDATE_ASPECTS_BASE"; payload: string[] }
  | { type: "ADD_ASPECT_TO_BASE"; payload: string }
  | { type: "REMOVE_ASPECT_FROM_BASE"; payload: string[] }
  | { type: "ADD_ASPECT_TO_DOCUMENT"; payload: string[] }
  | { type: "SET_ASPECT_FROM_DOCUMENT"; payload: string[] }
  | { type: "HIDE_TUTORIAL_AGREEMENT"; payload: boolean }
  | { type: "NEXT_HINT"; payload: number }
  | { type: "TOGGLE_INDEX_TUTORIAL"; payload: boolean }
  | { type: "TOGGLE_EDIT_MODAL_TUTORIAL"; payload: boolean }
  | { type: "TOGGLE_INSPECT_MODAL_TUTORIAL"; payload: boolean }
  | { type: "TOGGLE_TUTORIAL"; payload: boolean }

export type Dispatch = (action: Action) => void

export const reducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case "RESET":
      return action.payload
    case "SELECT_DOCUMENT":
      return { ...state, selectedDocument: action.payload }
    case "UPDATE_DOCUMENT_TEXT_INPUT":
      return {
        ...state,
        selectedDocument: {
          ...state.selectedDocument,
          textInput: action.payload,
        },
      }
    case "DATA_LOADING":
      return { ...state, dataLoading: action.payload }
    case "SET_OUTPUT":
      return { ...state, outputData: action.payload }
    case "SET_RESPONSE_ERROR":
      return { ...state, responseError: action.payload }
    case "SET_RESPONSE_ERROR_MSG":
      return { ...state, responseErrorMsg: action.payload }
    case "UPDATE_ASPECTS_BASE":
      return { ...state, aspectsBase: action.payload }
    case "ADD_ASPECT_TO_BASE":
      return { ...state, aspectsBase: [...state.aspectsBase, action.payload] }
    case "REMOVE_ASPECT_FROM_BASE":
      return { ...state, aspectsBase: action.payload }
    case "ADD_ASPECT_TO_DOCUMENT":
      return {
        ...state,
        selectedDocument: {
          ...state.selectedDocument,
          aspects: [...action.payload],
        },
      }
    case "SET_ASPECT_FROM_DOCUMENT":
      return {
        ...state,
        selectedDocument: {
          ...state.selectedDocument,
          aspects: action.payload,
        },
      }
    case "HIDE_TUTORIAL_AGREEMENT":
      return {
        ...state,
        tutorialAgreementVisibility: action.payload,
      }
    case "TOGGLE_INDEX_TUTORIAL":
      return {
        ...state,
        indexTutorialVisibility: action.payload,
      }
    case "TOGGLE_EDIT_MODAL_TUTORIAL":
      return {
        ...state,
        editTutorialVisibility: action.payload,
      }
    case "TOGGLE_INSPECT_MODAL_TUTORIAL":
      return {
        ...state,
        inspectTutorialVisibility: action.payload,
      }
    case "TOGGLE_TUTORIAL":
      return {
        ...state,
        tutorialVisibility: action.payload,
      }
    case "NEXT_HINT":
      return {
        ...state,
        tutorial: action.payload,
      }
    default:
      return state
  }
}
