const defaultErrorMsg = "We encountered an unexpected problem, please check your internet connection."
const connectionErrorMsg =  "The server was unable to compile the data, please try again. If the problem will occur again, please report the problem to our team."
const serverErrorMsg = "A server connection error has occurred, please check your internet connection."
const noDataErrorMsg = "No data received from API."


export const selectErrorMessage = errorType => {
    switch (errorType) {
        case '500':
            return connectionErrorMsg
        case 'no data':
            return noDataErrorMsg
        case 'default':    
        default:
            return defaultErrorMsg
    }
}

