import { outputData } from "../utils/outputDataExample"
import { selectErrorMessage } from "../utils/helpers"
export type documentTypes = {
  id: number
  textShort: string
  textInput: string
  aspects: string[]
}

export type outputDataTypes = {
  subTasks: {
    aspect: string
    examples: {
      alignment: number[][]
      aspect: string
      aspectRepresentation: {
        comeFrom: number[]
        lookAt: number[]
        tokens: string[]
      }
      aspectTokens: string[]
      patterns: {
        impact: number
        tokens: string[]
        weights: number[]
      }[]
      scores: number[]
      sentiment: string
      subtokens: string[]
      text: string
      textTokens: string[]
      tokens: string[]
    }[]
    scores: number[]
    sentiment: string
    text: string
  }[]
  text: string
}

export type AppState = {
  documents: documentTypes[]
  aspectsBase: string[]
  selectedDocument: documentTypes
  dataLoading: boolean
  responseError: boolean
  responseErrorMsg: string
  tutorialAgreementVisibility: boolean
  indexTutorialVisibility: boolean
  editTutorialVisibility: boolean
  inspectTutorialVisibility: boolean
  outputData: outputDataTypes
}

export const defaultState: AppState = {
  documents: [
    {
      id: 0,
      textShort: "Your own text",
      textInput: "",
      aspects: [],
    },
    {
      id: 1,
      textShort:
        "We are great fans of Slack, but we wish the subscriptions were more accessible to small startups.",
      textInput:
        "We are great fans of Slack, but we wish the subscriptions were more accessible to small startups.",
      aspects: ["slack", "price"],
    },
    {
      id: 2,
      textShort:
        "It uses to be my favorite show by far and I never thought that could change but it happened.",
      textInput:
        "It uses to be my favorite show by far and I never thought that could change but it happened. Last season I watched only once but all the seasons before that I have seen many times. Thank god that I did, because now I won’t watch the season again. You really mess it up with the last season.",
      aspects: ["show", "season"]
    },
    {
      id: 3,
      textShort:
        "Well, the customer service was good the 2 guys working were very friendly and polite.",
      textInput:
        "Well, the customer service was good the 2 guys working were very friendly and polite. On the other hand, the food was ehh I’ve had better. We ordered catfish which was good and the roasted chicken which was cold. The shrimp basket was spicy and tasty, but the collard greens were sweet. Unfortunately, cornbread was old like it’s been sitting all day but the butter they served with it was good. I’d advise not to get The Bloody Mary alcoholic drink.",
      aspects: [
        "service",
        "food",
        "drink",
        "catfish",
        "butter",
        "chicken",
        "shrimp",
        "cornbread",
      ]
    },
    {
      id: 4,

      textShort:
        "My wife and I and our 4 year old daughter stopped here Friday-Sunday.",
      textInput:
        "My wife and I and our 4 year old daughter stopped here Friday-Sunday. We arrived about midday and there was a queue to check in, which seemed to take ages to go down. Our check is aws fairly quick but not sure why others in front of us took so long. Our room was \"ready\" althought the sofa bed for our daughter hadn't been made. We went out for the day and arrived back about 7pm and the sofa bed still wasn't made!!! The location is sop on, a stones throw from the Eiffel tower and if you get the Le Bus Direct from the airport, the stop is also between tower and hotel. Breakfast was good enough, plenty of choice so you cant go hungry unless you're fussy.",
      aspects: [
        "reception",
        "bed",
        "service",
        "staff",
        "location",
        "public",
        "breakfast",
      ],
    },
  ],
  aspectsBase: [
    "bed",
    "service",
    "slack",
    "price",
    "reception",
    "staff",
    "location",
    "public",
    "breakfast",
  ],
  selectedDocument: {
    id: 0,
    textShort: "Your own text",
    textInput: "",
    aspects: [],
  },
  dataLoading: false,
  responseError: false,
  responseErrorMsg: selectErrorMessage("default"),
  tutorialAgreementVisibility: true,
  indexTutorialVisibility: false,
  editTutorialVisibility: false,
  inspectTutorialVisibility: false,
  outputData,
}
